export default function TimeLineCard({company, title, date, description, technologies, image, floatinText}) {
    return (
        <div className="cd-timeline-block">
            <div className="cd-timeline-img cd-picture" style={{backgroundImage: `url(${image})`}}></div>
            <div className="cd-timeline-content">
            <h5>{company}</h5>
            <div className="timeline-content-info">
                <span className="timeline-content-info-title">
                <i className="fa fa-certificate" aria-hidden="true"></i> {title} </span>
                <span className="timeline-content-info-date">
                <i className="fa fa-calendar" aria-hidden="true"></i> {date} </span>
            </div>
            <p>{description}</p>
            <span className="cd-date">{floatinText}</span>
            <ul className="content-skills">
                {
                    technologies.map((tech, i) => {
                        return (
                            <span className="badge rounded-pill bg-primary" key={i}>{tech}</span>
                        )
                    })
                }
            </ul>
            </div>
        </div>
    )
}