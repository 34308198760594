import './About.css'; 

export default function About() {
    return (
        <div className="container about-container">
            <h2 id="about-me">About Me</h2>
            <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-5 col-sm-12 img-me-wrapper" alt="me">
                    <div className="img-me" style={{backgroundImage: "url('meworking.jpeg')"}}>
                    </div>
                </div>
                <div className="col-xl-9 col-lg-8 col-md-8 col-sm-12 me-info">
                    <p>
                    Hi, My name is Rene. I'm a Computer Scientist from Mexico city. I've worked as a Full Stack developer for a variety of companies including e-commerce, consulting, and fintech.
                    My main skills include Javascript and Python technologies ranging from Front-end with React to Django and NodeJS for the backend. Currently learning Rust and sharpening my skills in design patterns. 
                    If you'd like my full work experience please contact me at <b>me@renegl.com</b> for the password and enter this link: <a href="/resume">Full Resume</a>
                    </p>
                </div>
            </div>
        </div>
    )
}