import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';
import ants from './images/ants.jpg';
import Latex from 'react-latex';
import YouTube from 'react-youtube';

export default function AntColonyPost() {

    return (
        <>
            <div className="row">
                <h2>Optimizando la Mejor Ruta con Algoritmo de Colonia de Hormigas en p5.js</h2>
                <p className="text-secondary">Jan 27, 2022</p>
            </div>
            <div className="row">
            <div >
                    <br />
                        <img src={ants} alt="ants" style={{width: "60vw"}} />
                        <br />
                    <br />
                </div>
                <p>
                La Cololnia de Hormigas es parte de las estrategias inspiradas en la naturaleza para resolver problemas donde no hay algoritmos eficientes 
                para llegar a una respuesta óptima. 
                Es un algoritmo Heurístico y ejemplo de lo que se denomina "Swarm intelligence" o Inteligencia de enjambre. 
                Está basado en el comportamiento de las hormigas exploradoras en búsqueda de comida en su entorno.
                <br />Al encontrar una fuente de comida, la hormiga deja feromonas para que sus compañeras encuentren el camino a la fuente. 
                Probablemente habrás visto como al dejar un dulce un el jardín pronto tienes un camino muy largo de hormigas bien organizadas llevando y trayendo comida a la colonia.
                A este mecanismo se le denomina "Estigmergia".

                En este post haremos una visualización del algoritmo en funcionamiento y como un grupo de hormigas pueden encontrar 
                un camino muy cerca del óptimo explorando su mundo representado por nodos conectados por caminos. Originalmente este tipo de heuristicas se usan 
                para resolver problemas mucho más díficil que encontrar el camino más corto, 
                como el <a href="https://es.wikipedia.org/wiki/Problema_del_viajante">Problema del agente viajero</a>, 
                pero para entender mejor el funcionamiento general de estos algoritmos es mejor visualizarlos con un caso simple. 
                Te recomiendo entiendas el problema del agente viajero para entender mejor este algoritmo. 
                </p>
                <h5>El Algoritmo Original</h5>
                <p>
                Como fuente estoy usando el libro "Clever Algorithms. Nature-Inspired Programming Recipes". <br />
                
                Hay dos cosas en las que el algoritmo original es diferente a como puedes pensar hormigas recorriendo su mundo. 
                Primero, recuerda que este algoritmo esta diseñado para resolver problemas como el agente viajero, 
                donde las soluciones son permutaciones de ciudades o puntos en el mapa. Su mundo, para simplificar el algoritmo, está
                completamente conectado, es decir, cada "nodo" (ciudad o punto) esta conectado con todos. Siempre puedes ir del nodo <tt>3</tt> al <tt>78</tt> por ejemplo.
                Esto hace generar permutaciones mucho más sencillo. Segundo las "hormigas" toman turnos para "recorrer" el mapa. Por recorrido, en este caso, 
                sería una permutación de ciudades válidas. Y realmente no hay hormigas. Es muy similar a los algoritmos genéticos, donde se mezclan permutaciones de soluciones 
                con ciertas métricas que nos pueden dar, con cada ciclo, mejores soluciones. Claro que no es inteligente simplemente elegir permutaciones aleatorias. Queremos mantener,
                ciertas permutaciones que tienen pinta de ser buenas, así que les damos mayor probabilidad de que sean elegidas en otras iteraciones. 
                Nos referimos por feromonas como eso, una pista que nos dice: 
                "¡He! por acá es más eficiente" y feromonas débiles (caminos que nos son muy eficientes) nos dan la pista que ir por ahí no es eficiente. 
                Dandonos pistas como estás y después de muchos ciclos podemos encontrar una solución cercana a la óptima. Bueno ahora va la jerga matemática. <br />

                El proceso de actualización de feromonas está dado por la siguiente ecuación: <br />
                </p>

                <div style={{textAlign: "center", fontSize: "20px"}}>
                    <Latex>{"$ \\tau_{i, j} = (1 - \\rho) \\times \\tau_{i, j} +  \\sum\\limits_{k=1}^{m} \\Delta^{k}_{i, j} $ "}</Latex>
                </div>
                <br />
                <br />
                <br />
                <p>
                    Donde <Latex>{"$ \\tau_{i, j} $"}</Latex> representa la feromona del camino que conecta a los nodos  <Latex>{"$ i, j $"}</Latex>. 
                    <Latex>{"$ \\rho $"}</Latex> es el "decay factor", es decir, la feromona irá perdiendo fuerza con el tiempo con el fin de no sesgar al algoritmo 
                    y darle demasiada fuerza a caminos que son muy cortos (normalmente se fija en <tt>0.5</tt>). En principio tomar caminos cortos parece buena idea, 
                    pero puede que exista un camino donde primero se da un paso largo,
                    pero los próximos nodos están muy cercanos a la comida. A veces hacer cosas que parecen poco eficientes es lo eficiente. 
                    <br/> Por último  <Latex>{"$ \\sum_{k=1}^{m} \\Delta^{k}_{i, j} $"}</Latex> es la suma de la "calidad" (maximización del costo) de los camino que incluyen <tt>i, j</tt>. 
                    Cuando un recorrido incluye <tt>i, j</tt> tomamos el costo de la solución (distancia final del recorrido) y añadimos a la feromona: <Latex>{"$ 1/S_{cost} $"}</Latex>
                    donde <Latex>{"$ S_{cost} $"}</Latex> es el costo final de la solución. Si es el costo es alto entonces su suma será más chica (ve la gráfica de <tt>1/x</tt>, 
                    también se puede usar algo más agresivo como <tt>100/x</tt>) Si el costo es bajo la feromona tendrá más fuerza y tendrá más probabilidades de ser elegidas.
                    <br />
                    <br />

                    El recorrido se construye por pasos, eligiendo uno a uno los caminos a las ciudades que aún no se visitan. 
                    Se elige con mayor probabilidad aquellas feromonas más fuertes. La probabilidad de elegir el camino <Latex>{"$ i, j $"}</Latex> 
                    se asigna con la siguiente ecuación: 

                </p>
                <div style={{textAlign: "center", fontSize: "20px"}}>
                    <Latex>{"$ P_{i, j} = \\dfrac{\\tau_{i, j}^{\\alpha} \\times \\eta_{i. j}^{\\beta}  }{\\sum_{k=1}^{c} \\tau_{i, k}^{\\alpha} \\times \\eta_{i. k}^{\\beta}} $ "}</Latex>
                </div>
                <br /> 
                <br /> 
                <br /> 
                <br /> 
                <p>
                    Donde <Latex>{"$ \\eta_{i, j} $"}</Latex> es simplemente, la distancia de elegir ese camino. También será en nuestro caso <Latex>{"$ 1.0/distance_{i, j} $"}</Latex> para maximizar soluciones que son bajas. 
                    <Latex>{"$\\alpha $"}</Latex> es el coeficiente heuristico. Vamos a elevar nuestra feromona a esa potencia para que sea mucho más significativas las feromonas que son fuertes. 
                    Por ejemplo, si las distancias fueran muy parecidas entre vecinos de una ciudad, queremos hacer esas diferencias más significativas. Se asigna a <tt>1.0</tt> pero puedes probar con distintos 
                    valores dependiendo de la instancia del problema. <Latex>{"$\\beta $"}</Latex> es coeficiente historico (lo mismo maximizar diferencias) 
                    y <Latex>{"$c$"}</Latex> el número de componentes disponibles, es decir, los vecinos del nodo actual que no hemos visitado. 
                    Todo esto lo dividimos por la suma de los demás caminos posibles para obtener un porcentaje. Digamos del 0-100 de que tan probable es que se elija ese camino. 
                    Por útimo lanzamos un número aleatorio <tt>r</tt> y si <Latex>{"$P_{i, j} \\geq r$"}</Latex> entonces elegimos ese camino. Poco a poco se irán reforzando 
                    los caminos con una distancia menor y que son incluídas en soluciones con costo bajo. Tras varios ciclos de esto se puede encontrar un recorrido bastante bueno. 
                    <br /> Aquí un psuedo-código que describe a grandes rasgos este procedimiento:
                    
                </p>
                    <SyntaxHighlighter language="javascript" style={tomorrow}>
                        {`
function search(graph, ants, alpha, beta) {
    best_sol = randomPermutation(graph.nodes);
    best_sol_cost = cost(best_sol); 

    // Se repite hasta que se tenga una solución satisfactoria
    // Puede ser num de ciclos o el costo de una solución
    while (!stop_condition()) {
        candidates = Array();
         // Cada Hormiga hace un recorrido
        for (ant in ants) {
            // Genera soluciones viendo feromonas!
            candidate_sol = generateAntPath(ant, graph, alpha, beta);
            cand_cost = cost(candidate_sol);
            // Si el costo es menor, este es nuestro mejor recorrido!
            if (cand_cost < best_sol_cost) {
                best_sol_cost = cand_cost; 
                best_sol = candidate_sol;
            }

            candidates.add(candidates)
        }

        // Decaemos todas las feromonas de la gráfica
        decayPheromones(graph);

        // Cada recorrido candidato es actualizado en las feromonas 
        // para evaluar su calidad y reforzar los caminos buenos.
        for (candidate in candidates) {
            updatePheromone(graph, candidate)
        }
    }

    return best_sol;
}
                        `}
                </SyntaxHighlighter>
                <p></p>
                <br />
                <br />
                <h5>Simulación en p5.js</h5>
                <p>Acá el video del algoritmo en funcionamiento: </p>
                <br />
                <br />
                <br />
                <div style={{textAlign: "center"}}>
                    <YouTube videoId="Cr9wMjtTRB4"  opts={{width: "80%"}}/> 
                </div>
                <p>
                <br />
                <br />
                    Puedes ver el código del proyecto en mi github acá: <a href="https://github.com/reneleyva/ant-colony-p5js.git">Link</a> <br />
                    Para implementarlo en P5.js fue bien interesante. Primero quería dibujar una gráfica triangular y uniformemente distribuída, para que no fuera difícil para mis hormigas de recorrer. 
                    Después de llorar varios días por no poder programar el algoritmo de Voronoi, pude hacer un script que dibujara gráficas triangulares 
                    por próximidad y genera un JSON con el siguiente formato:
                </p>

                <SyntaxHighlighter language="json" style={tomorrow}>
                        {`
{
    "nodes":[
       {
          "id":"0",
          "neighbours":[
             "34",
             "23",
             "89"
          ],
          "position":{
             "x":3948.23,
             "y":986.44
          }
       }, 
       {...}
    ]
}
                        `}
                </SyntaxHighlighter>

                <p>Puedes ver como la genero en el archivo <tt>GraphGenerator.js</tt> en el repositorio.<br /> 
                Tengo una clase <tt>Graph</tt> que recibe este JSON y lo dibuja, así como una clase <tt>Node</tt>. Cada <tt>Node </tt> 
                tiene una lista de vecinos y la relación ser "vecino" es reflexiva (Si nodo <tt>x</tt> es vecino de <tt>y</tt> entonces <tt>y</tt> es vecino de <tt>x</tt>)
                
                </p>

                <SyntaxHighlighter language="javascript" style={tomorrow}>
                        {`
class Node {
    constructor(id) {
        this.id = id;
        this.neighbours = {}
        this.position = createVector(0, 0);
    }

    setPosition(x, y) {
        this.position = createVector(x, y);
    }

    isNeigbour(node) {
        return node.id in this.neighbours
    }

    connect(node) {
        if (node.id === this.id) {
            throw Error("Intento de conectar el mismo nodo")
        }

        if (!this.isNeigbour(node)) {
            this.neighbours[node.id] = true;
            node.neighbours[this.id] = true;
        }
    }

    getNeighboursList() {
        return Object.keys(this.neighbours)
    }

    disconnect(node) {
        if (this.isNeigbour(node)) {
            delete this.neighbours[node.id];
            delete node.neighbours[this.id];
        }
    }
}


class Graph {
    constructor(N) {
        this.nodes = [];
        for (let i = 0; i < N; i++) {
            this.nodes.push(new Node(i.toString()))
        }
        this.pheromones = {};
        this.bestPath = [];
        this.bestPathDistance = Number.MAX_VALUE;
    }

    setBestPath(path, distance) {
        this.bestPath = path;
        this.bestPathDistance = distance;
    }

    /**
     * Connects and assigns positions to Graph Nodes
     * based on a JSON.
     * JSON of the format: {nodes: [<Node>, <Node>, ... ]}, where Node: 
     * {
     *  "id": 0,
     *  "neighbours":[id1, id2, ..., idn],
     *  "position": {"x": <float>, "y": <float> }
     * }
     * 
     */
    constructGraphFromJSON(jsonObj) {
        const jsonNodes = jsonObj["nodes"];
        jsonNodes.forEach(nodeObj => {
            const id = nodeObj.id;
            const neighbours = nodeObj.neighbours;
            const position = nodeObj.position;

            const graphNode = this.nodes[id];
            graphNode.setPosition(parseFloat(position.x), parseFloat(position.y));

            neighbours.forEach(neighId => {
                const graphNeigh = this.nodes[parseInt(neighId)];
                graphNode.connect(graphNeigh);
            })
        })
    }

    connect(id1, id2) {
        if (id1 < this.length && id2 < this.length) {
            const node1 = this.nodes[id1];
            const node2 = this.nodes[id2];
            node1.connect(node2)
        } else {
            throw Error("ID fuera de RANGO!")
        }
    }

    getNodeById(strId) {
        return this.nodes[int(strId)];
    }

    // ... 
}
                        `}
                </SyntaxHighlighter>
                <p>Cómo puedes ver <tt>Graph</tt> tiene ya algunos atributos para feromonas y para el mejor camino actual. Las pheromonas de la gráfica tienen como llave 
                <tt>i-j</tt> una cadena con un guión, con los id's de los nodos que representan un camino. El camino <tt>i-j</tt> es lo mismo que el camino <tt>j-i</tt>.
                Cada entrada de las feromonas en la gráfica tienen un objeto de tipo <tt>Pheromone</tt>. La clase <tt>Pheromone</tt> es bien sencilla: 
                <SyntaxHighlighter language="javascript" style={tomorrow}>
                        {`
class Pheromone {
    constructor(i, j) {
        this.decarFactor = 0.8;
        this.realValue = 0;
        this.i = i;
        this.j = j;
    }

    updatePheromone(value) {
        const cost = 1/value;
        this.realValue += cost;
    }

    decay() {
        const currRealValue = this.realValue;
        this.realValue = (1 - this.decarFactor) * currRealValue;
    }

    getRealValue() {
        return this.realValue;
    }
}
                        `}
                </SyntaxHighlighter>
                
                Sólo actualiza su valor actual dividiendo el costo de ir de <tt>i</tt> a <tt>j</tt> y <tt>decay()</tt> reduce la pheromona. 
                La clase complicada es <tt>Ant</tt>. Es nuestra hormiga, la resposable de ir recorriendo la gráfica y tomando desiciones. 
                La que le asigna a la gráfica el mejor camino que han recorrido. Describiré el comportamiento a grandes rasgos en pseudo-codifo 
                de la hormiga y puedes ver tú mismo el código real en javascript en el repositorio. 

                
                <SyntaxHighlighter language="javascript" style={tomorrow}>
                        {`
class Ant {
    constructor(graph) {
        this.graph = graph;
        this.pathTaken = []; // El camino que tomó hasta ahora
        ...
    }

    update() {
        // Si la hormiga no ha llegado a su nodo destino la movemos en pantalla 
        if (!arrivedAtDestination()) {
            moveToDestinationNode();
        } else {
            // Llego al destino
            chooseNextNode();
        }
    }

    chooseNextNode() {
        // si algún vecino es comida, ve a la comida! 
        if (someNeighborNodeIsFood()) {
            goToFood();
        } 

        //Lego a la casa de la cololnia
        if (arrivedHome()) {
            // Inicializamos todas sus banderas y su camino. 
            this.graph.setCandidate(this.pathTaken)
            resetAnt(); 
        }

        // Si llega a un callejon sin salida, hace backtrack para encontrar nodos a los que no ha visitado.
        if (alreadyVisitedNeighboirs()) {
            backTrack()
        }

        // Decide a donde ir por fermonoas de los vecinos del nodo actual
        next = chooseByPheromones(); 
        this.pathTaken.push(next)
        setDestination(next)
    }

    chooseByPheromones() {
        allPheromones = getNeighborsPheromones(); 
        // Obtiene las probabilidades de las feromonas de los vecinos

        chosen = random(allPheromones) // elegimos una aleatoria al principio

        for (p in allPheromones) {
            prob = p.getProbability(); // Obtenemos la probabilidad de elegir esta feromona
            // Lanzamos una moneda del 0 al 100. 
            // Las feromonas con más fuerza tendrán más probabilides.
            r = random(100)
            if (r < prob) {
                chosen = p;
            }
        }

        // Va al nodo de la feromona elegida.
        goToNextNodeBYPheromone(p)

    }

    display() {
        // Dibuja la Hormiga en pantalla
    }

}
                        `}
                </SyntaxHighlighter>

                </p>
            </div>
        </>
    )
}