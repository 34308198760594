import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';
import {useState} from 'react'; 

export default function ProblemasRec() {
    const [showSolDict, setShowSolDict] = useState({
        "sol-1": false,
        "sol-2": false,
        "sol-3": false,
    })

    const showSol = (id) => {
        // evt.preventDefault();
        showSolDict[id] = !showSolDict[id]; 
        setShowSolDict(showSolDict);
    }

    return (
        <>
            <div className="row">
                <h2>Tres Ejercicios de Recursión para Entrevistas en Javascript</h2>
                <p className="text-secondary">24 Dic, 2021</p>
            </div>
            <div className="row">
                <p>Recuerdo en una entrevista en línea me pidieron que mostrara mi pantalla y escribiera una función recursiva. 
                    Aunque tenía un poco de práctica me puse muy nervioso y logré hacerlo funcionar pero me mostré indeciso y lento. 
                    Aquí veremos uno de esos ejercicios y sus soluciones pero recomiendo que el lector trate de hacer los ejercicios por sí mismo 
                    antes de ver la solución. O no, la verdad ver la solución es buena práctica también.</p>

                    <b>Problema 1.</b>
                    <p>Escribe una función recursiva <tt>reversa(cadena) {}</tt> que recibe como argumento una cadena de texto y regresa la cadena como argumento pero en reversa. <br/>
                        Ejemplo: <tt>reverse("Hola")</tt> regresará <tt>"aloH"</tt>.
                    </p>
                    <a href="#a" onClick={() => showSol("sol-1")}> { showSolDict["sol-1"] ? "Ocultar solución" : "Mostrar solución" } <i className={`fas fa-sort-down`}></i></a>
                    {
                        showSolDict["sol-1"] && (
                        <div id="sol-1">
                            <p>Siempre que tenemos que constuir una función recursiva empezamos con su caso base. ¿Cual es el caso más simple del problema?: Una cadena vacía. (También podría ser una cadena con un sólo caracter)</p>
                            <SyntaxHighlighter language="javascript" style={tomorrow}>
                            {`function reversa(str) {
    if (str === "") return str;
}
                            `}
                        </SyntaxHighlighter>
                        <p>Bien ya sabemos hacer reversa para una cadena vacía, ahora lo complicado es la llamada recursiva. Sabemos que la cadena no es vacía. 
                            Necesitamos el último elemento de la cadena, eso se logra con <tt>str[str.length - 1] </tt> el último caracter lo tenemos que concatenar con su caracter anterior. 
                            Entonces llamamos a la función de nuevo pero con la cadena cortada del 0 a <tt>str.length - 1</tt>. Eso se logra con <tt>str.slice(0, str.length-1)</tt>. 
                            Tip: No tengas miedo de hacer pequeñas pruebas durante la entrevista. 
                            En la terminal escribe <tt>> node</tt> para obtener el interprete de node y haz pequeañas pruebas con <tt>slice()</tt>, por ejemplo: ¿slice() da error si el indice es inválido? </p>
                            <SyntaxHighlighter language="javascript" style={tomorrow}>
                            {`function reversa(str) {
    if (str === "") return str;
    return str[str.length -1 ] + reversa(str.slice(0, str.length-1))
}

console.log(reversa("Hola"))

// reversa("Hola)
// "a" + reversa("Hol)
// "a" + "l" + reversa("Ho)
// "a" + "l" + "o" + reversa("H)
// "a" + "l" + "o" + "H" + reversa("")
// "a" + "l" + "o" + "H" + ""
// Resultado: "aloH"
                            `}
                        </SyntaxHighlighter>
                        <br />
                        <br />
                        </div>

                        )
                    }
                <br />
                <br />
                <b>Problema 2.</b>
                <p>Escribir una función recursiva <tt>cuenta(n)</tt> que recibe <tt>n</tt> como argumento con <tt>n >= 0</tt>. 
                La función imprime en consola la cuenta regresiva hacia atrás desde <tt>n</tt> hasta cero. <br />
                Ejemplo: <tt>cuenta(8)</tt> imprime en consola: <br />
                <tt>>8</tt><br />
                <tt>>7</tt><br />
                <tt>>6</tt><br />
                <tt>>5</tt><br />
                <tt>>4</tt><br />
                <tt>>3</tt><br />
                <tt>>2</tt><br />
                <tt>>1</tt><br />
                <tt>>0</tt><br />
                </p>
                <a href="#a" onClick={() => showSol("sol-2")}> { showSolDict["sol-2"] ? "Ocultar solución" : "Mostrar solución" } <i className={`fas fa-sort-down`}></i></a>
                {
                    showSolDict["sol-2"] && (
                        <div>
                            <p>El caso más simple del problema es cuando <tt>n = 0</tt>.</p>
                            <SyntaxHighlighter language="javascript" style={tomorrow}>
                            {`function function cuenta(n) {
    if (n === 0) {
        console.log(n);
        return;
    }; 
}
                            `}
                        </SyntaxHighlighter>
                        <p>Imprimimos <tt>n</tt> pero no olidamos hacer <tt>return</tt> sino la función puede seguir ejecutandose infinitamente.</p>
                        <p>Bien ahora la llamada recursiva. </p>
                        <SyntaxHighlighter language="javascript" style={tomorrow}>
                            {`function cuenta(n) {
    if (n === 0) return;
    console.log(n);
    cuenta(n-1);
}

cuenta(10)
// 10
// 9
// 8
// 7
// ...
`}
                        </SyntaxHighlighter>
                        <p>Aquí me adelanté un poco y eliminé el <tt>console.log</tt> del caso base. 
                        Recomiendo estudiar lo que es la pila de llamadas por si te piden explicar la sucesión de llamadas a la función y como funciona internamente: <a href="https://es.wikipedia.org/wiki/Pila_de_llamadas#:~:text=En%20ciencias%20de%20la%20computaci%C3%B3n,de%20un%20programa%20de%20computadora.">Link</a></p>
                         <br />
                        </div>
                    )
                }
            <br />
            <br />
            <b>Problema 3.</b>
                <p> Escribir la función recursiva <tt>recorrer(arbol)</tt> con <tt>arbol</tt>   representado en un objeto JSON: <tt>{`{x1: {x2: ... }}`}</tt>. Cada valor del arbol tiene <tt>n</tt> sub-árboles <tt>n >= 0</tt>. 
                Cada valor del árbol entonces puede ser: <tt>null</tt>, <tt>{`{}`}</tt> (árbol vacío) ó <tt>{"x1, x2, ... , x3"}</tt> con <tt>xi</tt> sub-arboles.
                La función debe recorrer los valores del árbol en DFS hasta encontrarse con una cadena de texto, en ese caso imprimir en consola la longitud de esa cadena.<br />
                Ejemplo: 
                </p>
                <SyntaxHighlighter language="javascript" style={tomorrow}>
                {`const arbol = {
    x1: {
        x1: null, 
        x2: {
            x1: "hola"
        }
    },
    x2: {
        x1: {
            x1: {x1: "mundo"}
        }, 
        x2: {}
    },
    x3: "recursion",
    x4: {
        x1: "hola", 
        x2: {x1: {x1: {x1: {x1: {}, x2: "final"}}}}
    },
}

recorre(arbol);

//Resultado en consola: 
// > 4 
// > 5
// > 9
// > 4
// > 5
                `}
                </SyntaxHighlighter>

                <a href="#a" onClick={() => showSol("sol-3")}> { showSolDict["sol-3"] ? "Ocultar solución" : "Mostrar solución" } <i className={`fas fa-sort-down`}></i></a>
                {
                    showSolDict["sol-3"] && (
                        <>
                            <br />
                            <p>DFS significa <a href="https://es.wikipedia.org/wiki/B%C3%BAsqueda_en_profundidad">Depth First Search</a> y se refiere a recorrer el árbol primero en profundidad a diferencia de ir nivel por nivel. Pondré la solución para BFS (Breath First) más adelante. 
                            Primero el caso base: la cadena de texto que queremos. Usamos el operador <tt>typeof</tt> para verificar que es una cadena de texto.</p>
                            <SyntaxHighlighter language="javascript" style={tomorrow}>
                {`function recorre(arbol) {
    if (typeof arbol === 'string') {
        console.log(arbol.length)
        return;
    }
}
                `}
                </SyntaxHighlighter>
                <p>El siguiente caso base es que el árbol sea vacío (<tt>null</tt> o <tt>{`{}`}</tt>)</p>
                <SyntaxHighlighter language="javascript" style={tomorrow}>
                {`function isNullOrEmpty(arbol) {
    return arbol === null || Object.keys(arbol).length === 0;
}

function recorre(arbol) {
    if (typeof arbol === 'string') {
        console.log(arbol.length)
        return;
    }

    if (isNullOrEmpty(arbol)) {
        return;
    }                
}
                `}
                </SyntaxHighlighter>
                <p>Aquí agregamos una función extra <tt>isNullOrEmpty</tt> para que nuestro <tt>if</tt> sea legible. 
                En la función usamos la función especial <tt>Object.keys(arbol)</tt> para obtener las llaves de el objeto. Tip: No dudes en googlear alguna función como esta durante la entrevista o preguntar a tus entrevistadores.</p><br />
                <p>Luego el caso recursivo: </p>
                <SyntaxHighlighter language="javascript" style={tomorrow}>
                {`function isNullOrEmpty(arbol) {
    return arbol === null || Object.keys(arbol).length === 0;
}

function recorre(arbol) {
    if (typeof arbol === 'string') {
        console.log(arbol.length)
        return;
    }

    if (isNullOrEmpty(arbol)) {
        return;
    }

    Object.keys(arbol).forEach(key => {
        return recorre(arbol[key])
    })
}

recorre(arbol)

// Imprime: 
// 4
// 5
// 9
// 4
// 5
                `}
                </SyntaxHighlighter>
                <p>Llamamos recursivamente a la función con los sub-arboles del valor actual. <br />
                    La solución para BFS sería cambiar el orden en que se resuelven las llamadas recursivas. 
                    Recordemos que internamente javascript usa una Pila de llamadas cuando usamos recursión. 
                    Para BFS se usa una Cola o Queue, que nosotros debemos usar explicitamente. 
                    (Más sobre Queue y Stacks: <a href="https://es.wikipedia.org/wiki/Cola_(inform%C3%A1tica)#:~:text=Una%20cola%20(tambi%C3%A9n%20llamada%20fila,extracci%C3%B3n%20pull%20por%20el%20otro.">Link</a>)</p>
                    <SyntaxHighlighter language="javascript" style={tomorrow}>
                {`const arbol = {
    x1: {
        x1: null, 
        x2: {
            x1: "hola" //4 
        }
    },
    x2: {
        x1: {
            x1: {x1: "mundo"} // 5
        }, 
        x2: {}
    },
    x3: "recurs", // 6
    x4: {
        x1: "holasss", // 7 
        x2: {x1: {x1: {x1: {x1: {}, x2: "fin"}}}} // 3
    },
}


function isNullOrEmpty(arbol) {
    return !arbol || Object.keys(arbol).length === 0;
}

function recorreAux(heap) {
    if (heap.length === 0) return; 

    // Siguiente elemento (tomamos elemento al principio)
    let actual = heap.shift();

    if (isNullOrEmpty(actual)) {
        return recorreAux(heap);
    }

    if (typeof actual === 'string') {
        console.log(actual.length);
    } else {
        // Añadimos todos sus sub-arboles a la cola
        Object.keys(actual).forEach(key => {
            heap.push(actual[key])
        })
    }    
    
    return recorreAux(heap);
}

function recorre(arbol) {
    return recorreAux([arbol])
}

recorre(arbol)

// Imprime en consola: 
// 6
// 7
// 4
// 5
// 3
                `}
                </SyntaxHighlighter>
                <p>DFS irá nivel por nivel revisando el caso base e imprimirá la cadena de texto que se encuentre en los primeros niveles.  </p>
                        </>
                    )
                }
            </div>
        </>
    )
}