import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';
import  AnnealingSketch  from './sketches/AnnealingSketch';
import {useRef} from 'react';

export default function SimulatedAnnealing() {
    const p5Canvas = useRef(null); 

    return (
        <>
            <div className="row">
                <h2>Simulated Annealing: Algoritmo Inspirado en la Metalurgia</h2>
                <p className="text-secondary">March 16, 2022</p>
            </div>
            <div className="row">
                <p>
                Simulated Annealing (Recocido Simulado en español) es un algoritmo basado en un proceso metalúrgico que consiste en calentar un material 
                y lentamente enfriarlo en condiciones controladas para así incrementar el tamaño de sus cristales y reducir sus defectos. Con esto 
                se logra incrementar la durabilidad y la resistencia del material. Al calentar un material se incrementa la energía de sus atomos permitiendo 
                que se muevan libremente. Al enfriarlo lentamente logramos que los atomos encuentren una mejor configuración. En términos de programación, tendremos 
                un espacio de búsqueda de soluciones de un problema y permitiremos al algoritmo, al principio, ser flexible con la calidad de soluciones y explorar libremente 
                (calentamos el material); con el paso del tiempo nos volveremos más exigentes y permitiremos sólo mejoras locales significativas (enfriamos). Con esto se logra 
                salir de máximos locales posibles y llegar a mejores soluciones con el tiempo. Cómo ejemplo usaremos el problema del agente viajero, donde nuestro espacio de búsqueda
                será un recorrrido que pase por todas las ciudades y regrese al mismo punto. Trateremos de encontrar una solución cuya distancia total sea mínima.
                </p>

                <p>
                    Este es el algoritmo final en funcionamiento. Presiona Play para iniciar el algoritmo; para reiniciar los valores recarga la página jeje. 

                </p>

                <div id="p5-container" ref={p5Canvas}></div>
                <AnnealingSketch canvasParentRef={p5Canvas} />
                <h5>Procedimiento</h5>
                <p>
                    El procedimiento en pseudo-código es el siguiente: 
                </p>
                    <SyntaxHighlighter language="js" style={tomorrow}>
                        {`function annealing(cities, max_iter, max_temp, temp_change) {
    s_current = createInitialSolution(cities);
    best_cost = cost(s_current);
    temperature = max_temp; // Temperatura maxima
    for i in 0..iter_max  {
        s_candidate = createNewSolution(s_current);
        // Bajamos la temperatura. temp_change, en mi caso, lo asigné a 0.98
        temperature = temperature * temp_change 
        candidate_cost = cost(s_candidate)

        if candidate_cost < best_cost {
            // Encontramos una mejor solución!
            s_current = s_candidate;
            best_cost = candidate_cost
        } else if metropolis(best_cost, candidate_cost, temperature) > random() {
            // le damos oportunidad a peores soluciones para evitar maximos locales
            s_current = s_candidate;
            best_cost = candidate_cost
        }
    }

    return s_current;
}

function metropolis(best_cost, candidate_cost, temperature) {
    return Math.exp((best_cost - candidate_cost) / temperature)
}

                        `}
                    </SyntaxHighlighter>
                    <p>Tal vez te diste cuenta que en la simulación a veces empeora la distancia al principio. Eso es por la función <tt>metropolis</tt> que es la medida probabilística basada en el agoritmo Metropolis-Hastings 
                    para elegir soluciones que pueden ser peores en el momento pero que puede que nos saque de un máximo local. Con el tiempo, la probabilidad de elegir peores soluciones, irá disminuyendo significativamente. 
                    <br />
                    <br />
                        Para crear nuevas soluciones es usual usar la optimización <a href='https://en.wikipedia.org/wiki/2-opt'>2-opt</a> para mejorar la solución en vez de 
                        generar una aleatoriamente. Básicamente tratamos de evitar que los caminos se crucen en forma de <tt>x</tt> y reemplazarlo con ese camino en <tt>x</tt> pero en reversa.
                        Se puede ver en acción en la simulación en las últimas iteraciones.
                        El procedimiento es el siguiente:
                    </p>

                    <SyntaxHighlighter language="js" style={tomorrow}>
                        {`function two_opt(s_current) {
    // Elegimos dos cuidades al azar
    c1 = random(c_current);
    c2 = random(c_current);

    exclude = [c1];

    /* 
    Seleccionamos la ciudad anterior.
    Caso especial si c1 es la ciudada inicial: 
    su anterior es la última ciudad en el arreglo
    */
    prev = s_current.length-1 if c1 == 0 else c1-1;
    exclude.push(prev);

    // Seleccionamos el siguiente 
    // Caso especial si es la última ciudad
    next = 0 if c1 == s_current.length - 1 else c1+1;
    exclude.push(next);
    

    while exclude.includes(c2) {
        // elegimos otra ciudad si c2 está en los excluidos
        c2 = random(c_current)
    }

    if c2 > c1 {
        // Intercambiamos c1 por c2 si c2 es mayor
        c1, c2 = c2, c1;
    }

    // reemplazamos el tramo de recorrido en reversa
    s_current[c1..c2] =  s_current[c1..c2].reverse()
    return c_current;
}`}
                    </SyntaxHighlighter>
                <p>
                Y eso es todo, con esas dos funciones después un número de ciclos podemos encontrar una solución bastante buena. Este algoritmo también puede ser adaptado para optimización de funciones continuas.
                Puedes ver el código de la simulación incial implementada en P5.js aquí: <a href="https://github.com/reneleyva/rgl-blog-post-code/tree/master/simulatedAnealing">Link</a>
                </p>

            </div>
        </>
    )
}