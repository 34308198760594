import './Header.css';
import {
    Link,
} from "react-router-dom";

export default function Header() {
    return (
        <div className="container">
            <nav className="navbar navbar-expand-lg navbar-dark bg-primary" id="navbar">
                <Link to="/" className="navbar-brand"><h3>*Rgl</h3></Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarColor02" aria-controls="navbarColor02" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                    <div className="collapse navbar-collapse" id="navbarColor02">
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item">
                                <Link to="/about" className="nav-link">About</Link>
                            </li>

                            <li className="nav-item">
                                <Link to="/posts" className="nav-link">All Posts</Link>
                            </li>
                        </ul>
                    </div>
            </nav>
        </div>
    )
}