import djangoPrepush from '../../posts/djangoPrepush';
import rustFibo from '../../posts/rustFibonacci';
import ProblemasRec from '../../posts/problemasRec';
import OptimalStopingPost from '../../posts/optimalStoping';
import ProcessingWebCam from '../../posts/processingWebCam';
import AntColonyPost from '../../posts/AntColonyPost';
import SimulatedAnnealing from '../../posts/SimulatedAnnealing';

const ALL_POSTS = {
    "simulated-annealing-p5-espanol": {
        title: "Simulated Annealing: Algoritmo Inspirado en la Metalurgia",
        placeHolder: " Simulated Annealing (Recocido Simulado en español) es un algoritmo basado en un proceso metalúrgico que consiste ...",
        linkPosfix: "simulated-annealing-p5-espanol",
        component: SimulatedAnnealing,
        tags: ["p5.js", "optimization algorithms", "graph"]
    },
    "colonia-hormigas-simulacion": {
        title: "Optimizando la Mejor Ruta con Algoritmo de Colonia de Hormigas en p5.js",
        placeHolder: "La Cololnia de Hormigas es parte de las estrategias inspiradas en la naturaleza para resolver problemas ...",
        linkPosfix: "colonia-hormigas-simulacion",
        component: AntColonyPost,
        tags: ["p5.js", "optimization algorithms", "graph"]
    },
    "filtro-tiempo-real-processing": {
        title: "Filtro de WebCam en Tiempo Real con Processing",
        placeHolder: "Jugando con manipulación de imagenes en Processing, también se puede activar la web cam y manipular pixel ...",
        linkPosfix: "filtro-tiempo-real-processing",
        component: ProcessingWebCam,
        tags: ["processing", "java", "image processing"]
    },
    "encontrar-mejor-candidato-algoritmo": {
        title: "Cómo Encontrar al Mejor Candidato & Algoritmo de Optimal Stopping",
        placeHolder: " Todos estamos buscando algo. Si no es el amor, puede ser un trabajo, una casa o una nueva dirección de carrera...",
        linkPosfix: "encontrar-mejor-candidato-algoritmo",
        component: OptimalStopingPost,
        tags: ["python", "algorithms"]
    },
    "tres-ejercicios-recursion-entrevistas": {
        title: "Tres Ejercicios de Recursión para Entrevistas en Javascript",
        placeHolder: "Recuerdo en una entrevista en línea me pidieron que mostrara mi pantalla y escribiera una función recursiva...",
        component: ProblemasRec,
        linkPosfix: "tres-ejercicios-recursion-entrevistas",
        tags: ["code challenge", "js", "recursion"]
    },

    "rust-fibonacci-levels": {
        title: "4 Niveles de Dificultad de Fibonacci en Rust",
        placeHolder: "Todo programador en algún momento programa fibonacci en unas de sus formas....",
        component: rustFibo,
        linkPosfix: "rust-fibonacci-levels",
        tags: ["rust", "code challenge", "recursion"]
    },
    "better-django-prepush-script": {
        title: "Complete Django Prepush Script with Git Hook",
        placeHolder: "When a Django project gets big enough and the developer team is growing you need to hold some styling rules...",
        component: djangoPrepush,
        linkPosfix: "better-django-prepush-script",
        tags: ["django", "python", "prepush", "bash"]
    },
}

export default ALL_POSTS;