import './Posts.css';
import Card from '../Card/Card'; 
import {useLayoutEffect, useState} from 'react';
import ALL_POSTS from './postsList';

export default function Posts() {
    const [topics, setTopics] = useState([]);
    const [filter, setFilter] = useState([]); 
    const [posts, setPosts] = useState([]); 
    const [allPosts, setAllposts] = useState([])


    const buttonToggle = (evt) => {
        const classList = evt.target.classList;
        if (classList.contains("btn-primary")) {
            classList.remove("btn-primary")
            classList.add("btn-outline-primary")
        } else {
            classList.remove("btn-outline-primary")
            classList.add("btn-primary")
        }
    }

    const filterByTopic = (evt, tag) => {
        buttonToggle(evt);
        const index = filter.indexOf(tag); 
        if (index > -1) {
            filter.splice(index, 1)
            setFilter(filter)
        } else {
            filter.push(tag); 
            setFilter(filter)
        }

        const newPosts = allPosts.filter(post => {
            return filter.length === 0 || isInTopic(post, filter)
        })

        setPosts(newPosts)
    }

    const isInTopic = (post, topics) => {
        let result = false; 
        topics.forEach(tag => {
            if (post.tags.includes(tag)) result = true;
        })
    
        return result;
    }

    useLayoutEffect(() => {
        let filterTop = {};
        let all = [];
        Object.keys(ALL_POSTS).forEach(key => {
            const post = ALL_POSTS[key];
            all.push(post)
            post.tags.forEach(tag => {
                filterTop[tag] = true; 
            })
        })

        setTopics(Object.keys(filterTop))
        setPosts(all)
        setAllposts(all)

    }, []);

    return (
        <div className="container">
            <h2>Posts</h2>
            <p>Filter by Topic:</p>
            <div className="topics">
                
                    {
                        topics.map((top, i) => {
                            return (
                                // <a href="#">#{top}</a>
                                <button type="button" className="btn btn-outline-primary topic-btn" key={i} onClick={evt => filterByTopic(evt, top)}>#{top}</button>
                            )
                        })
                    }
            </div>
            {
                posts.map((post, i) => {
                    return (
                        <Card postTitle={post.title} postPlaceholder={post.placeHolder} tags={post.tags} key={i} postFix={post.linkPosfix}/>
                    )
                })
            }
        </div>
    )
}