import {useState} from 'react';

export default function Login({hanlder}) {
    const [pass, setPass] = useState("");
    const [invalid, setInvalid] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (pass === process.env.REACT_APP_RESUME_PASS) {
            hanlder(true);
        } else {
            setInvalid(true);
        }
    }

    return (
        <form onSubmit={(e) => handleSubmit(e)}>
            <fieldset>
                <h5>My Resume</h5>
            <div className="input-group mb-3">
                <input type="password" className="form-control" placeholder="Password" aria-label="Password" aria-describedby="button-addon2" value={pass} onChange={e => setPass(e.target.value)} />
                <button className="btn btn-primary" type="submit" id="button-addon2">Submit</button>
            </div>
                {
                    invalid && (<div className="row"><b className="form-text" style={{color: 'red'}}>Sorry, wrong password</b></div>)
                }
                <p>If you don't have a password, please contact me at: <b>me@renegl.com.</b></p>
            
            </fieldset>
        </form>
    )
}