import './SinglePost.css';
import ALL_POSTS from '../Posts/postsList';
import {
    useParams
} from "react-router-dom";

export default function SinglePost() {
    let { id } = useParams();
    return (
        <div className="App container post-content">
            {ALL_POSTS[id].component()}
            <div className="row back-to-top">
                <a href="#navbar"><b>Back to top<i className="fas fa-arrow-up" style={{marginLeft: "5px"}}></i></b></a>
            </div>
        </div>
    )
}