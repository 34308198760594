import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';
import prepushImg from './images/prepush.png'; 

export default function djangoPrepush() {

    return (
        <>
            <div className="row">
                <h2>Complete Django Prepush Script with Git Hook</h2>
                <p className="text-secondary">Dec 22, 2021</p>
            </div>
            <div className="row">
                <div>
                When a Django project gets big enough and the developer team is growing you need to hold some styling rules and make sure all the devs are abiding to those standars.
                A time ago I worked on a big Django project and we had a cool bash script tha ran all the python styling rules plus checking migrations, translations and tests.
                The script ran before making a push to the branch you were working on and I think is worth sharing. 
                We'll add 8 checks to our bash script:
                <br/>
                <br/>
                <ul>
                    <li><b>Uncommited changes:</b> Maybe you forgot to commit your work? </li>
                    <li><b>Black formatting:</b> The classic python code formatter (<a href="https://pypi.org/project/black/">Black pip doc</a>)</li>
                    <li><b>isort:</b> To sort imports alphabetically and check for unused imports (<a href="https://pypi.org/project/isort/">isort doc</a>)</li>
                    <li><b>flake8:</b> Tool For Style Guide Enforcement (<a href="https://flake8.pycqa.org/en/latest/">flake8 doc</a>)</li>
                    <li><b>Django migration:</b> To not forgot to run makemigrations and push them</li>
                    <li><b>Django compile messages:</b> To not forgot to run compilemessages and push the .mo's files</li>
                    <li><b>Django make messages:</b> To not forgot to run makemessages and push the .po's files</li>
                    <li><b>Django tests:</b> To check if all the test are successful </li>
                </ul>
                <p>Make sure you got <tt> black</tt>, <tt> isort</tt> and <tt>flake</tt> installed in your Django project and the tests run successfully.</p>
                <h4>Uncommited changes</h4>

                <div>
                    I'll write all the check in the same file called <tt>pre-push</tt> with no file extension.
                    <SyntaxHighlighter language="bash" style={tomorrow}>
                    {`
#!/bin/sh
# Terminal Colors
NC='\\033[0m'
RED='\\033[1;31m'
GREEN='\\033[1;32m'
ORANGE='\\033[1;33m'
BLUE='\\033[1;34m'

# check if uncommited changes
echo -n "\${ORANGE}CHECKING\${NC}: Uncommited changes ..."
if [ -n "$(git status --porcelain)" ]; then
	echo "             [ \${RED}FAILED! ಠ_ಠ \${NC} ]";
	echo "\${BLUE} Please commit or stash your local changes \${NC}";
	exit 1
else
      echo "         [ \${GREEN}OK (⌐■_■)\${NC} ]";
fi   
                        `}
                    </SyntaxHighlighter>
                    
                </div>
                
                First we add color to the terminal so the feedback is more readable. Then we check for uncommited changes with: <br/> <tt>git status --porcelain</tt> And we check is the result is not null. If is not null then there's uncommited changes, the pre-push fails and we exit with code 1.
                </div>

                <h4>Black formatting:</h4>
                <SyntaxHighlighter language="bash" style={tomorrow}>
                    {`
# ... 

# check if code is black formated
echo -n "\${ORANGE}CHECKING\${NC}: Black format..."
black --check --exclude venv/ . > /dev/null 2>&1
if [ $? -ne 0 ]; then
    echo "             [ \${RED}FAILED! ಠ_ಠ \${NC} ]";
    echo "\${BLUE}Apply: black --exclude venv/ .\${NC}";
    exit 1
else
    echo "                [ \${GREEN}OK (⌐■_■)\${NC} ]";
fi
                        `}
                    </SyntaxHighlighter>
                    <p>Here we check if <tt>black --check</tt> is successful. We add <tt>--exclude venv/</tt> to avoid formating the virtual environment. Trying to format the venv is a big no no.</p>
                    <h4>isort & flake8:</h4>
                    <SyntaxHighlighter language="bash" style={tomorrow}>
                    {`
# ... 

# check if code is isort formated
echo -n "\${ORANGE}CHECKING\${NC}: isort format..."
isort --check-only . > /dev/null 2>&1
if [ $? -ne 0 ]; then
    echo "                [ \${RED}FAILED! ಠ_ಠ\${NC} ]";
    echo "\${BLUE}Apply: isort .\${NC}";
    exit 1
else
    echo "                [ \${GREEN}OK (⌐■_■)\${NC} ]";
fi

# check flake8
echo -n "\${ORANGE}CHECKING\${NC}: Flake8 ... "
flake8 .
if [ $? -ne 0 ]; then
    echo "\n [ \${RED}FAILED! ಠ_ಠ\${NC} ] \n";
    echo "\${BLUE}Apply: flake8  .\${NC}";
    exit 1
else
    echo "                    [ \${GREEN}OK (⌐■_■)\${NC} ]";
fi
                        `}
                    </SyntaxHighlighter>
                    <p>Same for <tt>isort</tt> and <tt>flake8</tt> for flake8 there's no --check so we intead run flake and check if there's std output, if flake8 is successful it doesn't prompt anything.</p>
            </div>

            <h4>Migration & Translations:</h4>
            <SyntaxHighlighter language="bash" style={tomorrow}>
                    {`
# ... 

# check Migrations
echo -n "\${ORANGE}CHECKING\${NC}: Migrations  ... "
python manage.py makemigrations --check --dry-run> /dev/null 2>&1
if [ $? -ne 0 ]; then
    echo "               [ \${RED}FAILED! ಠ_ಠ\${NC} ] \n";
    echo "\${BLUE}Apply: python manage.py makemigrations \${NC}";
    exit 1
else
    echo "               [ \${GREEN}OK (⌐■_■)\${NC} ]";
fi

# check makemessages
echo -n "\${ORANGE}CHECKING\${NC}: Compile Makemessages ... \n"
./manage.py compilemessages -i venv
if [ -n "$(git status --porcelain)" ]; then
    echo "[ \${RED}FAILED! ಠ_ಠ \${NC} ]";
    echo "\${BLUE} Check the .mo's files in your branch and commit them \${NC}";
    exit 1
else
    echo "[ \${GREEN}OK (⌐■_■)\${NC} ]";
fi

# check makemessages
echo -n "\${ORANGE}CHECKING\${NC}: Translations Makemessages ... \n"
./manage.py makemessages --no-location -a -i venv
git diff --numstat | awk '{if ($1>1 || $2>1) { exit 1 } else { exit 0 }}' > /dev/null 2>&1
if [ $? -ne 0 ]; then
    echo "[ \${RED}FAILED! ಠ_ಠ \${NC} ]";
    echo "\${BLUE}Check the .po's changes and translate them \${NC}";
    exit 1
else
    echo "[ \${GREEN}OK (⌐■_■)\${NC} ]";
fi

                        `}
            </SyntaxHighlighter>
        <p>If you don't have translations in your project you can exclude the messages part.</p>
        <h4>Tests:</h4>
        <p>Finally check for tests with pytest and add a final success message.</p>
        <SyntaxHighlighter language="bash" style={tomorrow}>
                    {`
# ... 

# check Test
echo -n "\${ORANGE}CHECKING\${NC}: Tests  ... \n"
pytest --disable-pytest-warnings
if [ $? -ne 0 ]; then
    echo "[ \${RED}FAILED! ಠ_ಠ\${NC} ] \n";
    exit 1
else
    echo "[ \${GREEN}OK (⌐■_■)\${NC} ]";
fi


cat << "EOF"
⊂_ヽ
　 ＼＼
　　 ＼( ͡° ͜ʖ ͡°)
　　　 >　⌒ヽ
　　　/ 　 へ＼
　　 /　　/　＼＼
　　 ﾚ　ノ　　 ヽ_つ
　　/　/
　 /　/|
　(　(ヽ
　|　|、＼
　| 丿 ＼ ⌒)
　| |　　) /
ノ )　　Lﾉ
(_／
EOF

exit 0
                        `}
                    </SyntaxHighlighter>
                    <h4>Git hook</h4>
                    <p>Put the prepush file in the root of your project. You can run the script with: <tt>chmod +x pre-push && ./pre-push</tt>
                    <br /> It should prompy you with something like this:</p>
                    <img src={prepushImg} alt="prepush" />
                    <p>To add the git hook to run the script before every push run this in terminal: <tt>cp pre-push .git/hooks</tt></p>
                    <p>Try to push new changes and the script should run :)</p>
        </>
    )
}