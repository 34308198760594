import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';
import arbol from './images/arbolfibo.jpeg';
import freezer from './images/freezer.jpeg';
import Latex from 'react-latex';

export default function rustFibo() {
    return (
        <div>
            <div className="row">
                <h2>4 Niveles de Dificultad de Fibonacci en Rust</h2>
                <p className="text-secondary">Dec 23, 2021</p>
            </div>
            <div className="row">
                <p>Todo programador en algún momento programa fibonacci en unas de sus formas. Casi siempre como primer ejemplo de funciones recursivas (junto con factorial). 
                    Hoy veremos 4 soluciones, cada una con una técnica diferente y veremos su complejidad de ejecución y sus desventajas. 
                </p>
                <h5>Recursión(Recursión(Rec...))</h5>
                <div>
                    Recordemos la definición: <Latex>{"$F_n = F_{n-1} + F_{n-2}$"}</Latex> con <Latex>{"$F_0 = 1$"}</Latex> de ahí la clásica solución recursiva.
                    <SyntaxHighlighter language="rust" style={tomorrow}>
                        {`fn fibo(n: u32) -> u32 {
    match n {
        0 | 1 => 1,
        _ => fibo(n-1) + fibo(n-2)
    }
} 
                        `}
                    </SyntaxHighlighter>
                    Somos felices con esta solución hasta que nos dicen que el tiempo de ejecución de esta función es <Latex>{"$\\mathcal{O}(2^n)$"}</Latex> y te preguntas por qué tu función falla cuando calculas <tt>fibonacci(100)</tt>. Esta función produce las siguientes llamadas recursivas:
                    <div style={{textAlign: "center"}}>
                        <br />
                        <img src={arbol} alt="arbol" width="500" />
                    </div>

                    <br />
                    <p>Para calcular <tt>Fn</tt> se produce un arbol de ejecución con altura <tt>n</tt> con el caso base en cada una de sus hojas. ¿Entonces cuantos pasos dará el calculo? será el número de nodos en el árbol. 
                    Un árbol binario completo como el que tenemos con altura <tt>n</tt> tiene <Latex>{"$1 + 2 + 4 + .. + 2^n = 2^{n+1} -1$"}</Latex> nodos, es decir, daremos más de <Latex>{"$2^n$"}</Latex> pasos para llegar a nuestra respuesta. Para <Latex>{"$n=257$"}</Latex> tomaría más pasos que átomos en el universo. Aproximadamente claro. Más sobre arboles binarios: <a href="https://www.baeldung.com/cs/binary-tree-number-of-nodes-level">Link</a>
                    </p>
                </div>
                <h5>Solución Iterativa</h5>
                <p>Es es la solución que en una entrevista te confunde un poco qué variables sumar y sobre todo como llamarlas. 
                    Bueno unos intentos y recordar las llamadas recursivas aquí está:</p>
                <SyntaxHighlighter language="rust" style={tomorrow}>
                        {`fn fibo(n: u32) -> u32 {
    let mut fib = 1; // F1
    let mut fib_prev = 1; // F0
    let mut fib_prev_prev = 0; // F-1

    for _ in 0..n {
        fib = fib_prev + fib_prev_prev; 
        let tmp = fib_prev; // Variable temporal
        fib_prev = fib; 
        fib_prev_prev = tmp;
    }

    fib

} 
                        `}
                    </SyntaxHighlighter>
            <p>
                El truco a recordar es que se tiene que intercambiar los valores de las variables anteriores y se tiene que guardar en una variable temporal para no perder el valor. 
                La complejidad de ejecución es simplemente <Latex>{"$\\mathcal{O}(n)$"}</Latex> con memoria constante (siempre 4 variables). Una mejora bastante buena a la solución anterior. Pero espera hay más.
            </p>
            <p></p>
            <p></p>
            <h5>Programación Dinámica</h5>
            <div>
                La programación dinámica no es sólo un truco, es todo un tema que resuelve problemas muy complejos. Este es como el nivel básico de la programación dinámica. 
                ¿Recuerdas la solución recursiva inicial? Muuy lenta. ¿Y si la arreglamos? ¿Si forzamos que funcione como mi relación con mi ex en la preparatoria? Bueno hay una solución (A diferencia de mi relación, Ok ya).
            </div>
            <p>
                Revisa de nuevo la imagen del árbol anterior. ¿Te das cuenta como calculamos 2 veces <tt>Fn-2</tt>? Bueno ahí está el meollo del asunto. 
                La programación dinámica es recursión con memorización. Recordamos soluciones anteriores. 
            </p>
                <SyntaxHighlighter language="rust" style={tomorrow}>
                        {`use std::collections::HashMap;

fn fibo(n: u32, memo: &mut HashMap<u32, u32>) -> u32 { 
    match memo.get(&n) {
        Some(f) => *f, 
        None => {
            let f_res = fibo(n-1, memo) + fibo(n-2, memo);
            memo.insert(n, f_res); // I remember u
            f_res
        }  
    }
}

fn main() {
    let mut memo = HashMap::new(); 
    //valores iniciales
    memo.insert(0, 1);
    memo.insert(1, 1);

    for i in 0..10 {
        println!("{:?}", fibo(i, &mut memo));
    }
}
                        `}
                    </SyntaxHighlighter>
            <p>La solución dinámica irá guardando los calculos anteriores para construir los siguientes e irá uno por uno linearmente.
                Por lo tanto este algoritmo tarda, en el peor de los casos: <Latex>{"$\\mathcal{O}(n)$"}</Latex>.
                Dado que memorizamos los calculos anteriores la memoria crecerá en función de <tt>n</tt> y usará <Latex>{"$\\mathcal{O}(n)$"}</Latex> en memoria. </p>
            </div>
            <div style={{textAlign: "center"}}>
                <br />
                <img src={freezer} alt="freezer" width="500" />
            </div>
            <br/>
            <br/>
            <h4>Q-Matrix</h4>
            <p>
                Bueno ahora llegamos al último nivel. Este requiere un poco más de código pero es muy eficiente. 
                Donald Knuth (autor de The Art of Computer Programming) nos da la siguiente identidad: 
            </p>
            <div style={{textAlign: "center"}}>

                <Latex>{"$\\begin{pmatrix} F_{n+1} & F_{n} \\\\ F_n & F_{n-1}  \\end{pmatrix} = \\begin{pmatrix} 1 & 1 \\\\ 1 & 0  \\end{pmatrix}^n$"}</Latex>
            </div>
            <br />
            <div>
                Hay una prueba matemática rigurosa (se deja como ejercicio al lector ¯\_(ツ)_/¯). La Mátriz de la derecha es nuestra Mátriz Q. 
                Partiendo de la identidad, para calcular fibonacci <tt>n</tt> elevamos a la <tt>n-1</tt> potencia la Mátriz-Q: <Latex>{`$Q^{n-1}$`}</Latex> y su primer elemento será el <Latex>{`$F_n$`}</Latex> que buscamos.
                Esto suena como la solución iterativa un poco, pero hay un truco extra. Si <tt>n</tt> es potencia de 2 elevar a la potencia una mátriz es <Latex>{"$\\mathcal{O}(log_2(n))$"}</Latex>.
                <div>
                    Supongamos <Latex>{"$n=2^p$"}</Latex> con <Latex>{"$p \\in \\mathbb{N}$"}</Latex>. Entonces <Latex>{"$Q^n = Q^{n/2} \\cdot Q^{n/2} = (Q^{n/4} \\cdot Q^{n/4}) \\cdot (Q^{n/4} \\cdot Q^{n/4}) = ... $" }</Latex> 
                y bueno estamos dividiendo una potencia de 2 y eso es la función <Latex>{"$log_2 n$"}</Latex> <i>(Esto no es una prueba matemática, no se enojen conmigo).</i>
                <p>Pero...pero ¿y si <tt>n</tt> no es potencia de 2? Bueno todo número puede ser repesentado como sumas  de potencias de 2 (números binarios) con eso elevamos  <tt>Q</tt> a la potencia de los factores y multiplicamos de nuevo. 
                No sólo eso podemos memorizar los resultados de elevar a la potencia ciertos factores, como lo hicimos con la solución dinámica y por fin tenemos el n-Fibonacci en tiempo <Latex>{"$\\mathcal{O}(log_2(n))$"}</Latex>. Aquí el código.</p>
                </div>
            </div>
            <SyntaxHighlighter language="rust" style={tomorrow}>
                        {`

use std::collections::HashMap; 

#[derive(Copy, Clone)]
struct Matrix2x2 {
    values: [[u32; 2]; 2]
}

impl Matrix2x2 {
    fn new(x1: u32, x2: u32, x3: u32, x4: u32) -> Self {
        Matrix2x2 {
            values: [[x1, x2], [x3, x4]]
        }
    }

    fn multiply(&self, matrix: &Matrix2x2) -> Matrix2x2 {
        let a11 = self.values[0][0]* matrix.values[0][0] + self.values[0][1]*matrix.values[1][0];
        let a12 = self.values[0][0]* matrix.values[0][1] + self.values[0][1]*matrix.values[1][1];
        let a21 = self.values[1][0]* matrix.values[0][0] + self.values[1][1]*matrix.values[1][0];
        let a22 = self.values[1][0]* matrix.values[0][1] + self.values[1][1]*matrix.values[1][1];
        let result = Matrix2x2::new(a11, a12, a21, a22); 

        result
    }

    fn get_first_element(&self) -> u32 {
        self.values[0][0]
    }
}

struct QMatrix {
    memo: HashMap<u32, Matrix2x2>
}

impl QMatrix {
    fn new() -> Self {
        QMatrix {
            memo: HashMap::new()
        }
    }

    fn is_power_of_two(n: u32) -> bool {
        return (n & (n-1)) == 0;
    }

    /**
     * Obtiene la representacíon en potencias de 2.
     * Ejemplo n = 98. 98 en binario es "1100010"
     * esta función regresará [2^1, 2^5, 2^6]
     * pues 2^1 + 2^5 + 2^6 = 98
    */
    fn get_factors(n: u32) -> Vec<u32> {
        if Self::is_power_of_two(n) {
            return vec![n];
        }

        let b_str = format!("{:b}", n);
        let mut factors = vec![];

        // Lo recorremos en reversa 
        for (i, b_val) in b_str.chars().rev().enumerate() {
            if b_val == '1' {
                factors.push(u32::pow(2, i as u32))
            }
        }
        
        factors
    }

    /**
     * Elevamos la matriz a la p potencia. 
     * Se presupone que p es potencia de 2. 
     */
    fn rec_pow(&mut self, p: u32) -> Matrix2x2 {
        if p == 1 {
            return Matrix2x2::new(1, 1, 1, 0)
        } 

        match self.memo.get(&p) {
            Some(m) => m.clone(),
            None => {
                let matrix_pow = self.rec_pow(p/2); // llamada recursiva
                let new_matrix = matrix_pow.multiply(&matrix_pow); // se multiplica por ella misma
                let res = new_matrix.clone();
                self.memo.insert(p, new_matrix);
                
                res
            }
        }
    }

    /**
     * No sabemos si n es potencia de 2. 
     * Partimos n en factores y usamos estas potencias 
     * para obetener las matrices por factores.
     */
    fn pow_by_factor(&mut self, n: u32) -> Matrix2x2 {
        let factors = Self::get_factors(n);
        let mut matrices: Vec<Matrix2x2> = factors.iter().map(|p| self.rec_pow(*p)).collect();

        let mut res_matrix = matrices.pop().unwrap(); 

        for mat in matrices {
            res_matrix = res_matrix.multiply(&mat);
        }

        res_matrix
    }
}

fn q_fibo(n: u32, mut q_matix: QMatrix) -> u32 {
    q_matix.pow_by_factor(n).get_first_element()
}

fn fibo(n: u32) -> u32 {
    match n {
        0 => 1,
        _ => {
            return q_fibo(n, QMatrix::new())
        }
    }
}

fn main() {
    for i in 0..10 {
        println!("{:?}", fibo(i));
    }

    // 1
    // 1
    // 2
    // 3
    // 5
    // 8
    // 13
    // 21
    // 34
    // 55
}
                        `}
                    </SyntaxHighlighter>
        </div>
    )
}