import './Resume.css';
import Login from './Login'; 
import TimeLineCard from './TimeLineCard';
import {useState, useRef} from 'react';
import LinesSketch from './LinesSketch'; 

const jobs = [
    {
        company: "Volkswagen Financial Services",
        title: "Senior FullStack Developer", 
        date: "Apr. 2021 - Present", 
        description: "I'm currently working for the US based backend team. I'm collaborating on a project regarding third parties API's & contract validation. I'm learning a lot about AWS and building maintainable infrastructures. My main focus is developing a system for XML data cleaning and processing with NodeJS with typescript and serverless lambda functions development and testing.", 
        technologies: ["NodeJS", "Typescript", "AWS", "Jest"],
        image: "volkswagen.jpeg"
    },
    {
        company: "Mesada.io",
        title: "Web Architect", 
        date: "Dec. 2020 - Apr. 2021", 
        description: "Alongside a newly formed team of 12 engineers, we designed and built from scratch an MVP for both Web and Mobile to send money from the US to Mexico through cryptocurrency technologies. My day-to-day tasks were to organize and review the work of 10 of the team developers remotely. I was involved in the designing and building of the project API and front end.", 
        technologies: ["Python", "Django", "ReactJS", "React Native", "GraphQL"],
        image: "mesada.jpg"
    },
    {
        company: "Justo.mx",
        title: "Backend Team Lead", 
        date: "Dec. 2019 - Dec. 2020", 
        description: "Jüsto is an online supermarket based in Mexico City. I worked on the e-commerce platform building API's for the mobile app and the logistics platform. The main challenge was scaling the project and maintaining good standards of testing and best practices. Product Delivery was on the rise due to the pandemic and the team grew rapidly. The technical challenges involved various departments inside the company. Justo is now one of the biggest startups in Mexico.", 
        technologies: ["Python", "Django", "ReactJS", "GraphQL"],
        image: "justo.jpeg"
    },
    {
        company: "Mariachi.io",
        title: "Full Stack Developer", 
        date: "Ago. 2019 - Nov. 2019", 
        description: "Mariachi is a software consulting company. I worked with one of their clients on launching their delivery mobile application built in React Native and extended their API.", 
        technologies: ["ReactJS", "React Native", "NodeJS", "GraphQL"],
        image: "mariachi.jpeg", 
        floatingText: "Freelance Journey"
    },
    {
        company: "Vantage Point Consulting",
        title: "Salesforce Admin", 
        date: "Jul. 2019 - Ago. 2019",
        description: "I got my Salesforce Admin certification to help a US client automatize corporate processes in their salesforce platform. ", 
        technologies: ["Salesforce", "HTML", "CSS"],
        image: "vantage.jpeg", 
    },
    {
        company: "Guru de Viaje",
        title: "Full Stack Developer", 
        date: "Jun. 2018 - Mar. 2019",
        description: "Guru de Viaje is a very popular platform in Mexico for finding good prices for national and international flights. The newly formed team faced a lot of technical challenges regarding their WordPress platform. We helped build the first steps to migrating to React and to microservices. Guru was showcased on a episode of Shark Tank Mexico.", 
        technologies: ["Wordpress", "Python", "NodeJS", "GraphQL"],
        image: "guru.jpeg", 
        floatingText: "End Freelance Journey"
    },
    {
        company: "Memorick.com",
        title: "Full Stack Developer", 
        date: "Jun. 2017 - May. 2018",
        description: "Memorick is an online service for photographers around the world to sell photo sessions. I worked with a small team of developers on implementing new features and helped with the front-end design. I learned a lot of the bussiness side of the startup and was involved with the marketing team for gathering and cleaning user data.", 
        technologies: ["MeteorJS", "MongoDB", "Javascript"],
        image: "memorick.jpeg", 
    },
    {
        company: "Pedidos.com",
        title: "Jr. Software Developer", 
        date: "Mar. 2017 - Jul. 2017",
        description: "Pedidos.com is a popular e-commerce selling office equipment in Mexico City. I worked on the e-commerce side of the platform developing mostly in Java. Their constantly growing inventory prompt a big challenge on the database. I worked on making database calls more efficient in SQL as well as normalize the tables.", 
        technologies: ["Java", "JSP", "SQL Server"],
        image: "pedidos.jpeg", 
    },
    {
        company: "Education | UNAM",
        title: "Computer Science", 
        date: "Jan. 2014 - 2018",
        description: "I mainly enjoyed learning about Algorithms and Functional Programming.", 
        technologies: [],
        image: "unam.jpeg", 
        floatingText: "Jan 2014"
    },
]
export default function Resume() {
    const [isLogin, setIsLogin] = useState(false);
    const p5Canvas = useRef(null); 

    if (!isLogin) {
        return (
            <div className="container">
                <Login hanlder={setIsLogin}/>
            </div>
        )
    }
    return (
        <div className="container main-container">

            <div className="row me-info-container">
                <div className="img-wrapper col-sm-5 col-lg-5 ">
                    <div className="img-circle" style={{backgroundImage: "url('me.jpeg')"}}>
                    </div>
                </div>
                <div className="resume-info col-sm-7 col-lg-7 ">  
                    <h2 id="name-h2">Rene Guerra Leyva</h2>
                    <h3 id="title-h3"> <small className="text-muted">Full Stack Engineer</small></h3>
                    <h4 id="contact-me-h4">Get In Contact</h4>
                    <div className="contact-info-wrapper">
                        <div className="contact-info">
                            <small><i className="fas fa-mobile-alt"></i>(+52) 5548919138</small>
                            <small><i className="fas fa-envelope"></i>me@renegl.com</small>
                        </div>
                        <div className="contact-info">
                            <small>
                                <i className="fab fa-linkedin"></i>
                                <a href="https://www.linkedin.com/in/ren%C3%A9-leyva-065ab5158/" target="_blank" rel="noreferrer">
                                    Rene Leyva
                                </a>
                            </small>
                            <small><i className="fas fa-map-marker-alt"></i>Mexico City</small>
                        </div>
                    </div>
                </div>
            </div>
            
        <div className="row">
            <h3 className="exp-title">Work Experience</h3>
            <section id="cd-timeline" className="cd-container">
            {
                jobs.map((job, i) => {
                    return (
                        <TimeLineCard 
                            company={job.company} 
                            title={job.title} 
                            description={job.description}
                            date={job.date}
                            technologies={job.technologies}
                            image={job.image}
                            floatinText={job.floatingText}
                            key={i}
                        />
                    )
                })
            }           
            </section>
        </div>
        <br />
        <br />
        <br />
        <div className="row exp-title">
            <h2>Personal Interest</h2>
            <div className="personal-int">
                <ul>
                    <li>Processing & Algorithms: 
                    <br /> Checkout my list of blog posts here: <a href='https://www.renegl.com/posts'>https://www.renegl.com/posts</a> </li>
                    <li><i className="fas fa-running"></i>Running</li>
                    <li>Jui Jitsu</li>
                </ul>
            </div>
            
            {/* <div id="p5-container" ref={p5Canvas}></div>
            <LinesSketch canvasParentRef={p5Canvas}/>
            <p>Built with p5.js</p> */}
        </div>
        </div>
    )
}