import './Card.css'; 
import {
  Link,
} from "react-router-dom";

export default function Card({postTitle, postPlaceholder, tags, postFix}) {
    return (
        <Link to={`/post/${postFix}`} className="card-link">
          <div className="card border-secondary mb-3">
            <div className="card-body">
              <h5 className="card-title">{postTitle}</h5> 
              <p className="card-text text-muted">{postPlaceholder}</p>
              <div className="card-tags">
                {
                  tags.map((tag, i) => {
                    return (
                      <span className="badge rounded-pill bg-primary" key={i}>#{tag}</span>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </Link>
    )
}