import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';
import filter from './images/filter.gif';
import pixel from './images/pixel.jpeg';

export default function ProcessingWebCam() {

    return (
        <>
            <div className="row">
                <h2>Filtro de WebCam en Tiempo Real con Processing</h2>
                <p className="text-secondary">Jan 4, 2022</p>
            </div>
            <div className="row">
                <p>
                    Jugando con manipulación de imagenes en Processing, también se puede activar la web cam y manipular <tt> pixel x pixel </tt> y aplicar efectos como el siguiente: 
                </p>
                <div style={{textAlign: "center"}}>
                    <br />
                        <img src={filter} alt="Filter" style={{width: "35em"}} />
                    <br />
                </div>
                <p>
                <br />
                    Excluyendo la compresión del .gif del ejemplo, se ve más claro cuando lo corres tú mismo. 
                    Teniendo el método para manipular los colores de los pixeles puedes aplicar tus propios filtros o dibujar diferentes formas.
                </p>


                <div>
                    <h5>El Método</h5>
                    <p>
                        El método básico es el "Pixeleo" de una imagen. Consiste en tomar bloques de pixeles de la imagen, tomar su color promedio y dibujar ese color promedio en bloques de ese tamaño.
                        Recordemos que cada pixel tiene 3 valores númericos: Rojo, Verde y Azul (RGB). 
                        Podríamos, por ejemplo, tomar un bloque de 5x5 pixeles, obetener su promedio RGB y dibujar un pixel gigante de 5x5 con ese color. 
                    </p>
                    <div style={{textAlign: "center"}}>
                        <br />
                            <img src={pixel} alt="Pixel" style={{width: "40em"}} />
                    </div>

                    <br />
                    <p>Processing nos da un arreglo de pixeles con el método <tt>loadPixels()</tt> de la clase <tt>PImage</tt> que hace muy sencillo manipular imagenes. 
                    El reto es que nos da un arreglo lineal de pixeles y no, como pensaríamos,  una cuadricula o mátriz. Para dividir en bloques es un tanto intricado.
                    En vez de cuadros, dibujaremos elipses pero puede ser cualquier otra cosa (Letras, figuras, etc). Pero primero vamos a activar la Web Cam con Processing.</p>

                    <h5>Processing.video</h5>
                    <p>Primero debes instalar processing junto con su IDE, donde correremos nuestro código. Lo puedes instalar <a href="https://processing.org/download">Aquí</a> <br />
                        Ya que lo tienes instalado, vamos al menú del IDE y selecciona la opción: <tt>Sketch -&gt; Add Library</tt> y busca Video library en el buscador o dentro de las opciones.
                        Una vez instalado, debes reiniciar el IDE para que funcione. No me funcionó a la primera en MacOS. Ahora vamos a probar el siguiente código.
                    </p>
                </div>
                    <SyntaxHighlighter language="java" style={tomorrow}>
                        {`import processing.video.*;

Capture cam;
Capture setupWebCam() {
    String[] camerasList = Capture.list();
    Capture camera; 
    if (camerasList.length == 0) {
        println("There are no cameras available for capture.");
        exit();
    } 


    camera = new Capture(this, camerasList[0]);
    camera.start();

    return camera; 
}

void setup() {
    size(1024, 768);
    cam = setupWebCam();
}

void draw() {
    if (cam.available() == true) {
        cam.read();
    }
    
    image(cam, 0, 0); 
}`}
                    </SyntaxHighlighter>
                    <p>Debería mostrar la cámara frontal de tu equipo. 
                        <br /> 
                        <br /> 
                        En el método <tt>draw()</tt> leemos frame por frame lo que nos devuelve la cámara. La variable <tt>cam</tt> es de tipo <tt>Capture</tt> y se comporta muy similar a una imagen que leímos de un archivo. Tiene un método <tt>loadPixels()</tt> y con eso podemos nosotros mostrar filtros diferentes.
                        Guardemos el archivo actual como "WebCamFilter" y vamos a crear un archivo "Filter" en el mismo  proyecto (en las pestañas presiona el dropdown y elige New Tab). 
                        Y apliquemos un filtro para cada frame que nos devuelve la cámara.
                    </p>
                    <SyntaxHighlighter language="java" style={tomorrow}>
                    {`// WebCamFilter

Capture cam;
Filter filter; 

Capture setupWebCam() {
    // ...
}

void setup() {
    size(1024, 768);
    cam = setupWebCam();
    filter = new Filter(); // init filter
}

void draw() {
    if (cam.available() == true) {
        cam.read();
    }
    
    background(255);
    filter.applyFilter(cam); // aplicando para cada frame
}`}
                    </SyntaxHighlighter>
                    <p>Ahora nuestra clase Filter</p>
                    <SyntaxHighlighter language="java" style={tomorrow}>
                    {`// Filter

// Tamaños de bloques de pixeles
int BLOCK_HEIGHT = 10; 
int BLOCK_WIDTH = 10; 

public class Filter {
    public void applyFilter(Capture image) {
        process(image);
    }

    private void process(Capture img) {
        img.loadPixels();
        // TODO ...
    }

    // Dibuja una elipse como pixel
    private void paintPixEllipse(float x, float y, int blockWidth, int blockHeight, color sqrColor) {}

    // Obtiene el color promedio de un bloque
    private color getAvgColorFromSqr(Capture img, int start, int blockWidth, int blockHeight) {}
}
                    `}
                </SyntaxHighlighter>
                <p>Vamos a implementar <tt>getAvgColorFromSqr</tt> que regresa el color promedio de un bloque. </p>
                <SyntaxHighlighter language="java" style={tomorrow}>
                    {`private color getAvgColorFromSqr(Capture img, int start, int blockWidth, int blockHeight) {
    int currRow = 0; 
    int rowEnd = start + blockWidth; 
    int rowStart = start;

    int numRows = blockHeight; 

    float rSum = 0; 
    float gSum = 0; 
    float bSum = 0; 

    while (currRow != numRows) {
        for (int i = rowStart; i < rowEnd; i++) {
            rSum += red(img.pixels[i]);
            gSum += green(img.pixels[i]);
            bSum += blue(img.pixels[i]);
        }

        rowStart += img.width; 
        rowEnd = rowStart + blockWidth; 
        currRow++; 
    }

    int totalPixels = blockWidth * blockHeight; 
    return color(rSum/totalPixels, gSum/totalPixels, bSum/totalPixels);
}
                    
                    `}
                </SyntaxHighlighter>
                <p>Como decía antes, siendo el arreglo <tt>img.pixels</tt> lineal, tenemos que recorrer por filas. 
                Cada que acabamos una fila le sumamos el ancho de la imagen al indice para "saltar" a la próxima fila del bloque un nivel abajo. Ahora <tt>paintPixEllipse</tt>
                </p>
                <SyntaxHighlighter language="java" style={tomorrow}>
                    {`private void paintPixEllipse(float x, float y, int blockWidth, int blockHeight, color sqrColor) {
    fill(sqrColor);
    noStroke();
    ellipse(x, y, blockWidth, blockHeight);
}
                    `}
                </SyntaxHighlighter>
                <p>Esté método simplemente pinta nuestra ellipse deacuerdo al color promedio de un bloque.</p>
                <p>
                    Ahora el método importante que processa el frame. Es un poco largo e intricado y el lector podría hacer algo más legible, pero hay varios problemas que encontré programando éste método. 
                    Por ejemplo, si queremos bloques de 10x10 y la imagen es de 333x458 por ejemplo no caben los bloques perfectamente y necesitamos un bloque "offset" que pinte bloques de tamaños irregulares de lo que sobra.
                    Otro es que la imagen que me daba la web cam era muy pequeña así que le agregué un incremento a los bloques. Al final este el archivo Filter. 
                </p>

                <SyntaxHighlighter language="java" style={tomorrow}>
                    {`int BLOCK_HEIGHT = 10; 
int BLOCK_WIDTH = 10; 
int inc = 10;

public class Filter {
    public void applyFilter(Capture image) {
        this.process(image);
    }

    private void paintPixEllipse(float x, float y, int blockWidth, int blockHeight, color sqrColor) {}

    private color getAvgColorFromSqr(Capture img, int start, int blockWidth, int blockHeight) {}
    
    private void process(Capture img) {
        img.loadPixels();
        
        int numSqrsPerRow = ceil(img.width/BLOCK_WIDTH); 
        int numSqrsPerColumn = ceil(img.height/BLOCK_HEIGHT); 

        int lastSqrOffsetWidth = img.width % BLOCK_WIDTH; 
        int lastSqrOffsetHeight = img.height % BLOCK_HEIGHT; 

        int sqrsPainted = 0; 
        int columnsPainted = 0; 
        int start = 0;

        float x = 0; 
        float y = 0; 

        while (columnsPainted != numSqrsPerColumn) {
            while (sqrsPainted != numSqrsPerRow) {
                if (columnsPainted == (numSqrsPerColumn -1) && lastSqrOffsetHeight > 0) {
                    color avgColor = getAvgColorFromSqr(img, start, BLOCK_WIDTH, lastSqrOffsetHeight);
                    this.paintPixEllipse(x, y, BLOCK_WIDTH + inc, lastSqrOffsetHeight + inc, avgColor);

                } else {
                    color avgColor = getAvgColorFromSqr(img, start, BLOCK_WIDTH, BLOCK_HEIGHT);
                    this.paintPixEllipse(x, y, BLOCK_WIDTH + inc, BLOCK_HEIGHT + inc, avgColor);
                }


                start += BLOCK_WIDTH;
                sqrsPainted++;
                x += BLOCK_WIDTH + inc;
            }

            if (lastSqrOffsetWidth != 0) {
                if (columnsPainted == (numSqrsPerColumn -1) && lastSqrOffsetHeight > 0) {
                    color avgColor = getAvgColorFromSqr(img, start, lastSqrOffsetWidth, lastSqrOffsetHeight);
                    this.paintPixEllipse(x, y, lastSqrOffsetWidth + inc, lastSqrOffsetHeight + inc, avgColor);

                } else {
                    color avgColor = getAvgColorFromSqr(img, start, lastSqrOffsetWidth, BLOCK_HEIGHT);
                    this.paintPixEllipse(x, y, lastSqrOffsetWidth + inc, BLOCK_HEIGHT + inc, avgColor);
                }

                start += lastSqrOffsetWidth; 
                x += lastSqrOffsetWidth + inc; 

            }

            columnsPainted++; 
            sqrsPainted = 0;
            x = 0;
            y += BLOCK_HEIGHT + inc;
            start += (img.width * (BLOCK_HEIGHT-1));

        } // end while
    }
}

                    `}
                </SyntaxHighlighter>
                <p>Puedes cambiar <tt>paintEllipse</tt> para pintar cualquier otra cosa, o aplicar otros filtros como negativo de un pixel o prueba pintar letras :).</p>
            </div>
        </>
    )
}