import Header from './components/Header/Header'; 
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";
import Home from './components/Home/Home'; 
import About from './components/About/About';
import Posts from './components/Posts/Posts';
import Resume from './components/Resume/Resume';
import SinglePost from './components/SinglePost/SinglePost';

function App() {
  return (
    <Router>
      <Header />

      <Switch>
          <Route exact path="/">
              <Home />
          </Route>
          <Route path="/posts">
              <Posts />
          </Route>
          <Route path="/about">
              <About />
          </Route>
          <Route path="/resume">
              <Resume />
          </Route>
          <Route path="/post/:id" children={<SinglePost />} />
      </Switch>
    </Router>
  );
}

export default App;
