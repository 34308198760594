import './Home.css';
import Card from '../Card/Card'; 
import ALL_POSTS from '../Posts/postsList';

function Home() {
  return (
    <div className="App container">
        <div className="row about">
          <h4>Hellow</h4>
          <p>I'm a software developer trying to navigate a world with so many tools and things to learn. I develop in JS, python and currently learning Rust. 
            Here you can read about some things I find interesting. </p>
        </div>

        <div>
        <h4>Contact me</h4>
            <ul>
              <li>me@renegl.com</li>
              <li><a href="https://github.com/reneleyva" target="blank">https://github.com/reneleyva</a></li>
            </ul>
        </div>

        <div>
          <h4>Recent Posts</h4>
            {
                Object.keys(ALL_POSTS).slice(0, 4).map(key => {
                    const post = ALL_POSTS[key];
                    return (
                        <Card postTitle={post.title} postPlaceholder={post.placeHolder} tags={post.tags} key={key} postFix={key}/>
                    )
                })
            }
        </div>

    </div>
  );
}

export default Home;
