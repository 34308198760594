import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';
import obsessed from './images/obsessed.jpeg';

export default function OptimalStopingPost() {

    return (
        <>
            <div className="row">
                <h2>Cómo Encontrar al Mejor Candidato & Algoritmo de Optimal Stopping</h2>
                <p className="text-secondary">Dec 28, 2021</p>
            </div>
            <div className="row">
                <div> 
                    Todos estamos buscando algo. Si no es el amor, puede ser un trabajo, una casa o una nueva dirección de carrera. 
                    Pero, en algún momento, detenemos nuestra busqueda y nos preguntamos: ¿Cuando dejar de buscar? No tenemos tiempo infinito. Tal vez la mejor opción
                    ya la hemos explorado, o tal vez una opción mejor nos espera si seguimos pacientemente explorando. ¿Cómo definimos mejor? 
                    Bueno para nuestra suerte, este problema ha sido estudiado extensivamente y dió origen a un area de estudio que denominan problemas de "Opmital Stopping". 
                    No se sabe el origen del primero de estos problemas, pero se considera fue publicado en la revista "Scientific American" en 1960 y fue de boca en boca 
                    entre los matemáticos de la época. El problema fue nombrado "The Secretary Problem" donde se busca el mejor candidato para un puesto y dice así: 
                    <figure className="text-center">
                        <br />
                        <blockquote className="blockquote">
                            <p className="mb-0 quote"> 
                            De un conjunto de cien candidatos se quiere encontar el mejor.
                            Se entrevista uno a uno de forma aleatoria. 
                            No hay métrica general para medir los candidatos, pero si cuál es su posición o "rank" frente a los demás. (Uno es mejor que otro)
                            Si se le oferta el puesto a un candidato este acepta al momento, pero si se decide seguir buscando, el candidato actual es rechazado y no se puede volver a tomar en cuenta para el puesto. </p>

                        </blockquote>
                    </figure>
                </div>
                <p>Este es un problema que se plantean los reclutadores día a día. Aunque, evidentemente, con muchas más variables. 
                    Hay dos formas en que se puede fallar en nuestra busqueda por nuestro candidato "ideal": 
                    Comprometernos muy rápido con un candidato que muestra potencial al principio o esperar demasiado tarde a un candidato mejor que no existe.
                    La respuesta, sorprendentemente, es simple y a la vez nos da una perpectiva a como podríamos tomar mejores desiciones: 37%. 
                    </p>
                    
                <h5>37%</h5>
                <p>37% es nuestra parada óptima en nuestra búsqueda y la programaremos en breve como prueba émpirica. La estrategia a tomar la denominan "Look-Then-Leap Rule": 
                    No importa que tan impresivos sean los candidatos al principio, esperamos a recabar información, rechazando al montón inicial, y nos detenemos al 37%. Después del 37% estamos listos para hacer nuestro "Leap", nuestro salto de fe en la incertidumbre. 
                    Nos comprometemos inmediatamente con el primer candidato que encontremos que sea mejor que los 37 anteriores. ¿Cuál es la probabilidad de encontrar al mejor candidato con ésta estrategia? Sorprendentemente: 37%. 
                    Es una de esas igualdades matemáticas satisfactorias. 37% aún así no es muy esperanzador. Al menos con esto tenemos una guía, para no comprometernos muy rápido o muy tarde. 
                    Tenemos 63% de probabilidad de fallar. Pero es mucho mejor que al azar donde tenemos <tt>1%</tt> de probabilidad.
                    (Veremos una variate donde los candidatos nos pueden rechazar y una donde rechazamos candidatos en la primera entrevista pero podemos volver a ofertarles).
                    <br />

                     </p>
                    <div>

                        Haremos una competencia del mejor reclutador donde tendremos a 3 participantes: 
                        <ul>
                            <li>El "Random": Este reclutador elige al azar, no le importa su trabajo.</li>
                            <li>El Facilmente impresionable: Lanzaremos una "moneda" con cada candidato en función de su ranking.</li>
                            <li>El Óptimo: Sigue la regla del 37% al pie de la letra.</li>
                        </ul>

                        Cada participante recibirá los mismos candidatos en el mismo orden y veremos quien da más. Les daremos mil candidatos y repetiremos esta prueba diez mil veces.
                        Lo genial de 37% es que no cambia con el número de opciones, tendrá la misma probabilidad de encontrar al mejor.
                        Pondré el código de cada participante al final del posts. Aquí los resultados: 
                        <br />
                        <br />
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                <th scope="col">Reclutador</th>
                                <th scope="col"># de éxitos encontrando al mejor</th>
                                <th scope="col">Tasa de éxito de la estrategia</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">Ruclutador Aleatorio</th>
                                    <td>5</td>
                                    <td>0.5%</td>
                                </tr>
                                <tr>
                                    <th scope="row">Reclutador Impresionale</th>
                                    <td>14</td>
                                    <td>1.4%</td>
                                </tr>
                                <tr className="table-success">
                                <th scope="row">Reclutador Optimo</th>
                                    <td>3680</td>
                                    <td>36.8%</td>
                                </tr>
                            </tbody>
                            </table>
                    
                        Puedes correr tú mismo mi código y obtendrás resultados muy cercanos. (Código al final del posts)
                    </div>
                    <div>
                        <br />
                        <br />
                        <h5>¿Y si me rechazan? :( </h5>
                        
                        En el libro "Algorithms To Live By" donde explican un poco más del origen y variantes de este algoritmo mencionan a Michael Trick, un estudiante
                        de universidad en busca del amor. Michael cae en la cuenta que buscar pareja es el mismo dilema de "The Secretary Problem". No sabes cuantas parejas potenciales 
                        conocerás pero la regla del 37% también puede ser un lapso de tiempo. Si su busqueda empieza de los 18 a los 40 años, la regla del 37% pondría nuestro Leap a los 26.1 años.
                        Justo la edad de Michael en el momento.  
                        Michael conoce a su chica "ideal" que considera su mejor opción. El algoritmo le dice que debe comprometerse.
                        Michael le propone matrimonio y ... es rechazado. <br />
                        No todos los candidatos dirán que sí a la primera oferta obviamente. Digamos que tienes 50% de posibilidad que un candidato te rechace. La mejor estrategia en este caso:
                        Oferta rápido y consistente. La regla aquí sería empezar a hacer propuestas despues de un cuarto de tu espacio de búsqueda. Si te rechazan, venga hay más peces en el mar, 
                        sigue ofertando al mejor que has visto hasta el momento. Tus probabilidades de éxito con esta estrategia será también 25%. 
                        
                    </div>


                    <div>
                        <br />
                        <br />
                        <h5>¿Y si vuelves con tu ex? </h5>
                            <div style={{textAlign: "center"}}>
                                <br />
                                <img src={obsessed} alt="obsessed" width="500" />
                                <br />
                                <br />
                            </div>
                        
                        En cuanto a candidatos de trabajo, después de la entrevista inicial, puedes esperar a ver otras opciones y después ofertarles. Pero no siempre en el amor. 
                        Supongamos que una oferta en el momento es siempre aceptada, pero regresar a ofertarles después de la entrevista inicial tiene un 50% de probabilidad que te rechacen. 
                        La estrategia aquí sería explorar en un 61% y desde ahí ofertar inmediatamente a cualquiera que sea mejor que los anteriores. Si no encuentras a nunguno mejor, entonces no queda más 
                        que volver con los anteriores y ofertarles a los mejores vistos anteriormente hasta que uno te acepte. La simetría se mantiene y con esta estrategia tienes un 61% de posibilidad de encontrar al mejor del conjunto de búsqueda. 
                        Moraleja: Tal vez no es tan mala desición volver con tu ex. 
                        <br />
                        <br />
                    </div>
                
                <p>Aquí el código de la estrategia inicial:</p>
                <SyntaxHighlighter language="python" style={tomorrow}>
                    {`import random

class Recruiter:
    def __init__(self, candidates):
        self.candidates = candidates
        self.sucess_count = 0 
        self.number_of_trials = 0
        self.best_candidate = len(self.candidates) - 1

    def set_candidates(self, candidates): 
        self.candidates = candidates
        self.best_candidate = len(self.candidates) - 1

    def do_trial(self):
        choice = self.pick_candidate()
        if choice == self.best_candidate:
            self.sucess_count += 1
        
        self.number_of_trials += 1
        
    def print_analisis(self):
        print("Trials: ", self.number_of_trials)
        print("Sucess Rate", self.sucess_count)

    def pick_candidate(self):
        pass


class RandomRecruiter(Recruiter):
    def pick_candidate(self):
        return random.choice(self.candidates)

class LuckRecruiter(Recruiter):
    """
    Si encuentra un mejor candidato que los anteriores
    Lanza un entero "r" aleatorio entre 0 y el # de candidatos
    Si r es menor a la probabilidad actual. Escogemos a ese candidato. 
    Si no, multiplicamos la probabilidad de que suceda:
    El reclutador se vuelve "impresionable" por cada mejor candidato.
    """
    def pick_candidate(self):
        curr_max = -1
        curr_prob = 1 # Probabilidad actual 1/(# candidatos)
        best = self.candidates[0]
        for cand in self.candidates:
            if cand >= curr_max:
                toss = random.randint(0, len(self.candidates))
                if toss < curr_prob:
                    best = cand
                    break
                
                curr_prob += 1

        
        return best


class OptimalRecruiter(Recruiter):
    def  pick_candidate(self):
        max_37 = -1
        stop_at_37 = int(len(self.candidates) * 0.37)
        best = None

        for i in range(0, stop_at_37):
            if self.candidates[i] > max_37:
                max_37 = self.candidates[i]
        
        for i in range(stop_at_37, len(self.candidates)):
            if self.candidates[i] > max_37:
                best = self.candidates[i]
                break

        if (not best):
            best = self.candidates[-1]

        return best
        

if __name__ == '__main__':
    candidates = [i for i in range(0, 1000)]

    random_rec = RandomRecruiter([])
    luck_recruiter = LuckRecruiter([])
    opt_rec = OptimalRecruiter([])

    for i in range(0, 10):
        random.shuffle(candidates)

        random_rec.set_candidates(candidates)
        opt_rec.set_candidates(candidates)
        luck_recruiter.set_candidates(candidates)

        random_rec.do_trial()
        opt_rec.do_trial()
        luck_recruiter.do_trial()
    
    print("*"*10)
    print("Random:")
    random_rec.print_analisis()
    print("*"*10)
    print("Lucky")
    luck_recruiter.print_analisis()
    print("*"*10)
    print("Optimal")
    opt_rec.print_analisis()
    print("*"*10)
    
    

                    `}
                </SyntaxHighlighter>
            </div>
        </>
    )
}